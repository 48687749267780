import React from "react";
import rushiImg from "../../assets/rushiImg.png";
import medicalAstronomyGreen from "../../assets/medical_astrology_green.png";
import "./index.css";

export default function About() {
  return (
    <>
      <div className="section" id="about">
        <div className="d-none d-md-block position-absolute right-img animate-right">
          <img src={medicalAstronomyGreen} alt="" />
        </div>
        <div className="row">
          <div className="d-flex justify-content-center">
            <div className="section-heading">About</div>
            <div className="section-header-border" />
          </div>
        </div>
        <div className="container mt-2">
          <div className="row">
            <div className="col-12 col-lg text-center">
              <img src={rushiImg} alt="" />
              <div className="rushi-name">Rishi Parashara maharaj</div>
            </div>
            <div className="col-12 col-lg">
              <div className="about-para1">
                <p>
                  The name “MASTROCLICK” is formed by combining two important
                  aspects. ‘M’ stands for Medical (Ayurveda & Herbal products)
                  and ‘ASTRO’ stands for Vedic Astrology (Parashari). The word
                  ‘CLICK’ symbolizes that; these two great subjects at your
                  fingertips. Astrology is the ancient science to understand the
                  influence of the planets and stars of the human life. Ayurveda
                  and Astrology have established in India since the time
                  immemorial. Astrology and Ayurveda both preserved in
                </p>
              </div>
            </div>
            <div className="col-12 col-lg">
              <div className="about-para1">
                <p>
                  Indian holy scriptures and transmitted generation to
                  generations by Great Sages, Mathematicians and Experts.
                  “MASTROCLICK” – Established to bridge the gap between present
                  generation and an ancient knowledge of the great Indian sages.
                  Here, at “MASTROCLICK” well experienced experts providing
                  effective astrological remedies & Ayurvedic/Herbal products
                  for prevention of ill health, care and cure. The divine
                  strategies will be implemented through vedic astrology base.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
