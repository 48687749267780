import React from 'react';

function Progressbar(props) {
    return (
        props.open && <div class="modal show d-block fade" style={{ backdropFilter: "brightness(0.4)" }}>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: props.progress || '100%' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Progressbar;