import React from "react";

function ArticleManagement(props) {
  return (
    <div className="content">
      <div className="container">
        <table className="table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Title</th>
              <th>Description</th>
              <th>Days</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Image</td>
              <td>Title</td>
              <td>Description</td>
              <td>Days</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ArticleManagement;
