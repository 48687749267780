import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { isAuthenticated, loginToGoogle } from "../../services/firebase";

export default function Admin() {
  useEffect(() => {
    isAuthenticated().then((user) => {
      if (!user) {
        loginToGoogle()
      }
    }).catch(console.log)
  })
  return (
    <>
      <Sidebar />
      <Outlet />
    </>
  );
}
