/**
 * validates for indian phone number
 * @param {string} phone 
 * @returns {boolean} true when phone number is valid
 */
export const isPhoneNumber = (phone) => /^(0|91)?[6-9][0-9]{9}$/.test(phone);
/**
 * validates generic email pattern
 * @param {string} email 
 * @returns {boolean} true when provided string is valid email
 */
export const isValidEmail = (email) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
/**
 * 
 * @param {Date} pickedDate 
 * @returns true when provided date is before current date
 */
export const isDateBeforeCurrentDate = (pickedDate) => pickedDate <= new Date();