import React, { useEffect, useState } from "react";
import { getOrders, markOrderAsCompleted } from "../../../services/firebase";
import "./index.css";

function OrderManagement(props) {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    getOrders().then((db_o_list) => {
      if (db_o_list.hasChildren()) {
        let tempOrderSet = [];
        db_o_list.forEach((db_o) => {
          const completionRef = db_o.child("completed").ref;
          const completed = db_o.child("completed").val();
          const birthPlace = db_o.child("birthPlace").val();
          const dob = db_o.child("dob").val();
          const dot = db_o.child("dot").val();
          const email = db_o.child("email").val();
          const fullName = db_o.child("fullName").val();
          const gender = db_o.child("gender").val();
          const language = db_o.child("language").val();
          const note = db_o.child("note").val();
          const phone = db_o.child("phone").val();
          const screenShot = db_o.child("screenShot").val();
          tempOrderSet.push({
            completionRef,
            completed,
            birthPlace,
            dob,
            dot,
            email,
            fullName,
            gender,
            language,
            note,
            phone,
            screenShot,
          });
        });
        setOrders(tempOrderSet.reverse());
      }
    });
  }, []);

  const markAsCompleted = (order) => {
    markOrderAsCompleted(order.completionRef);
  };

  return (
    <div className="container">
      <div className="accordion mt-4" id="accordionExample">
        {orders.map((order, index) => (
          <div className="accordion-item mt-4">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${index}`}
                aria-expanded="true"
                aria-controls={`${index}`}
              >
                {order.fullName}
                {order.completed && (
                  <span className="completed-checkbox">
                    <input
                      className="form-check-input mt-0"
                      type="checkbox"
                      checked={true}
                      value={order.completed}
                      aria-label="Checkbox for following text input"
                    />
                  </span>
                )}
              </button>
            </h2>
            <div
              id={`${index}`}
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-6">
                    <div>
                      <strong>Full Name :</strong> {order.fullName}
                    </div>
                    <div>
                      <strong>Gender :</strong> {order.gender}
                    </div>
                    <div>
                      <strong>Date of Birth :</strong> {order.dob}
                    </div>
                    <div>
                      <strong>Place of Birth :</strong> {order.birthPlace}
                    </div>
                    <div>
                      <strong>Birth Time :</strong> {order.dot}
                    </div>
                    <div>
                      <strong>Language :</strong> {order.language}
                    </div>
                    <div>
                      <strong>Email :</strong> {order.email}
                    </div>
                    <div>
                      <strong>Phone Number :</strong> {order.phone}
                    </div>
                    <div>
                      <strong>Note :</strong> {order.note}
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary mt-4"
                      onClick={() => markAsCompleted(order)}
                    >
                      Mark as Completed
                    </button>
                  </div>
                  <div className="col-6">
                    <img src={order.screenShot} alt="" height={300} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderManagement;
