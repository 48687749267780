import React from "react";
import logoImg from "../../assets/logo.png";
// import sendImg from "../../assets/send.png";
import "./index.css";

export default function Footer() {
  // const quickLinks = [
  //   { name: "Home", link: "/" },
  //   { name: "About", link: "/" },
  //   { name: "Services", link: "/" },
  //   { name: "Shop", link: "/" },
  //   { name: "News", link: "/" },
  //   { name: "Contact", link: "/" },
  // ];
  return (
    <>
      <div className="mt-5" id="contact">
        <div className="footer-section">
          <div className="container">
            <div className="row justify-content-center justify-content-lg-start pt-lg-5">
              <div className="col-sx-12 col-lg-6 text-center text-lg-start pt-5 pt-lg-0">
                <img src={logoImg} style={{ maxWidth: "100%", maxHeight: "350px" }} alt="" />
              </div>

              <div className="col-sx-12 col-lg-6 text-center">
                <div className="row">

                  <div className="col-sx-12 col-md-6 col-lg-6">
                    <div className="footer-section-text quick-links">
                      Contacts
                    </div>
                    <div className="footer-section-text link-items">
                      mastroclick@gmail.com
                    </div>
                    <div className="footer-section-text link-items">
                      +91 8296231302
                    </div>
                    <div className="footer-section-text link-items">
                      Pragatinagar 10th cross Sirsi
                    </div>
                  </div>
                </div>

                {/* <div className="d-flex flex-row">
                  <div className="email-address">
                    <input
                      className="form-control "
                      type="text"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="message">
                    <input
                      className="form-control "
                      type="text"
                      placeholder="Enter your message"
                    />
                  </div>
                  <div className="send-btn">
                    <button type="button" className="btn btn-light">
                      <img src={sendImg} height="22" alt="" />
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="horizontal-divider"></div>
          <div className="footer-section-text copyright-text text-center">
            All rights reserved Mastroclick.com
          </div>
        </div>
      </div>
    </>
  );
}
