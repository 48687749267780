import React, { useEffect, useState } from "react";
import GalleryCard from "../../../components/GalleryCard";
import Modal from "../../../components/Modal";
import {
  uploadGalleryImage,
  setGallery,
  getGalleryData,
} from "../../../services/firebase";

export const getDays = (timeStamp) => {
  const today = new Date().getTime();
  const differenceMs = today - timeStamp;
  const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  return daysDifference;
};

export default function GalleryManagement() {
  const initalData = {
    title: "",
    image: "",
    days: "",
  };
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState(initalData);
  const [file, setFile] = useState();
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    getGalleryData().then((db_gal_list) => {
      if (db_gal_list.hasChildren()) {
        let tempGallery = [];
        db_gal_list.forEach((db_gal) => {
          const title = db_gal.child("title").val();
          const image = db_gal.child("image").val();
          const days = getDays(db_gal.child("created_at").val());
          tempGallery.push({
            title,
            image,
            days,
          });
        });
        setGalleryData(tempGallery);
      }
    });
  }, []);

  const submitAndUploadData = () => {
    if (file) {
      setShowModal(false);
      uploadGalleryImage(file, state.title)
        .then((fileUrl) => {
          setGallery({ ...state, image: fileUrl })
            .then(() => {
              setState(initalData);
            })
            .catch((error) => {
              console.error(error);
              alert(
                "Sorry. something went wrong. check your network before trying again"
              );
            });
        })
        .catch((e) => {
          console.error(e);
          alert(
            "Sorry. something went wrong. check your network before trying again"
          );
        });
    } else {
      alert("Please upload a screenshot of payment");
    }
  };

  return (
    <div className="container mt-4">
      <div className="row">
        {galleryData.map((gallery) => {
          return (
            <div className="col-12 col-lg-3 mb-1">
              <GalleryCard
                image={gallery.image}
                heading={gallery.title}
                days={gallery.days}
              />
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="mt-4 btn btn-primary btn-lg"
          onClick={() => setShowModal(true)}
        >
          Upload Image
        </button>
      </div>

      <Modal
        open={showModal}
        title="Upload Gallery"
        onClose={() => setShowModal(false)}
        onButtonClick={submitAndUploadData}
        btnTitle="DONE"
      >
        <div className="row mt-2">
          <div className="col-12">
            <span className="field-label">Title</span>
            <input
              value={state.title}
              onChange={(e) =>
                setState({
                  ...state,
                  title: e.target.value,
                })
              }
              className="form-control "
              type="text"
              placeholder="Enter title of the image"
            />
          </div>

          <div className="col-12 mt-2">
            <input
              type="file"
              accept="image/jpeg"
              onChange={(e) => setFile(e.target.files[0])}
              name="upload image"
              id="image"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
