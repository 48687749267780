import React from "react";
import UserForm from "../../components/UserForm";
import logoImg from "../../assets/logo.png";
import "./index.css";
import { useEffect } from "react";
import startAnimate from "../Utils/randomText";

export default function Home() {
  useEffect(() => {
    startAnimate();
  }, []);
  return (
    <div className="home-section">
      <canvas id="canvas"></canvas>
      <div className="container main-form">
        <div className="row justify-content-center justify-content-lg-start pt-lg-5">
          <div className="col-sx-12 col-lg-6 text-center text-lg-start header-container">
            <img
              className="logoImage mx-auto mx-lg-none"
              src={logoImg}
              alt=""
            />
            <div className="text-heading-1">Vedic Astrological Predictions</div>
            <div className="text-heading-2">KNOW YOUR HEALTH </div>
            <div className="text-heading-1">
              For Beneficial living & Holistic well being
            </div>
            <button type="button" className="btn btn-danger btn-lg contact-btn" onClick={() => window.open("tel:+918296231302")}>
              CONTACT
            </button>
          </div>

          <div className="col-sx-12 col-lg-6 d-flex flex-column justify-content-center">
            <UserForm />
          </div>
        </div>
      </div>
    </div>
  );
}
