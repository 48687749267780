import React from "react";
import { useState } from "react";
import { isDateBeforeCurrentDate, isPhoneNumber, isValidEmail } from "../../containers/Utils/validators";
import { placeOrder, uploadScreenshot } from "../../services/firebase";
import Modal from "../Modal";
import "./index.css";
import qrcode from "../../assets/QRCode.jpeg";
import Progressbar from "../Progressbar";

export default function UserForm() {
  const initalData = {
    fullName: '',
    gender: 'Male',
    dob: '',
    dot: '',
    birthPlace: '',
    phone: '',
    email: '',
    note: '',
    language: 'Kannada'
  }
  const [state, setState] = useState(initalData);
  const [showModal, setShowModal] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showUploadConfirmation, setUploadConfirmation] = useState(false);
  const [file, setFile] = useState();

  const submit = () => {
    if (!isPhoneNumber(state.phone.replace('+', ''))) {
      alert("Please enter valid phone number");
      return
    }
    if (!isValidEmail(state.email)) {
      alert("Please enter valid email");
      return
    }
    if (!isDateBeforeCurrentDate(new Date(state.dob))) {
      alert("Invalid Date of Birth");
      return
    }
    setShowModal(true);
  }

  const submitAndUploadData = () => {
    if (file) {
      setShowModal(false);
      setShowProgress(true);
      uploadScreenshot(file, state.phone.replace('+', '')).then((fileUrl) => {
        placeOrder({ ...state, screenShot: fileUrl }).then(() => {
          setShowProgress(false);
          setUploadConfirmation(true);
          setState(initalData);
        }).catch(error => {
          console.error(error);
          setShowProgress(false);
          alert('Sorry. something went wrong. check your network before trying again');
        });
      }).catch(e => {
        console.error(e);
        setShowProgress(false);
        alert('Sorry. something went wrong. check your network before trying again');
      })
    } else {
      alert('Please upload a screenshot of payment');
    }
  }

  return (
    <div className="form-container">
      <div className="row mt-2">
        <div className="col-sm-12 col-md-8">
          <span className="field-label">Full Name *</span>
          <input
            value={state.fullName}
            onChange={(e) => setState({
              ...state,
              fullName: e.target.value
            })
            }
            className="form-control "
            type="text"
            placeholder="Enter your Full Name"
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <select
            value={state.gender}
            onChange={(e) => setState({
              ...state,
              gender: e.target.value
            })}
            className="form-select gender"
            aria-label="Gender"
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Transgender">Transgender</option>
          </select>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-sm-12 col-md">
          <span className="field-label">Date of birth *</span>
          <input
            max={new Date().toISOString().split("T")[0]}
            value={state.dob}
            onChange={(e) => setState({
              ...state,
              dob: e.target.value
            })}
            className="form-control "
            type="date"
            placeholder="DD-MM-YYYY"
          />
        </div>
        <div className="col-sm-12 col-md">
          <span className="field-label">Time of birth *</span>
          <input value={state.dot} onChange={(e) => setState({
            ...state,
            dot: e.target.value
          })} className="form-control " type="time" placeholder="HH MM" />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col">
          <span className="field-label">Birth Place</span>
          <input
            value={state.birthPlace}
            onChange={(e) => setState({
              ...state,
              birthPlace: e.target.value
            })}
            className="form-control "
            type="text"
            placeholder="Enter your birth place"
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-sm-12 col-md">
          <span className="field-label">Phone (whatsapp) *</span>
          <input
            value={state.phone}
            onChange={(e) => setState({
              ...state,
              phone: e.target.value
            })}
            className="form-control "
            type="phone"
            placeholder="Enter your phone number"
          />
        </div>

        <div className="col-sm-12 col-md">
          <span className="field-label">Email *</span>
          <input
            value={state.email}
            onChange={(e) => setState({
              ...state,
              email: e.target.value
            })}
            className="form-control "
            type="email"
            placeholder="Enter your email"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <span className="field-label">Note to Mastro</span>
          <textarea
            value={state.note}
            onChange={(e) => setState({
              ...state,
              note: e.target.value
            })}
            className="form-control "
            type="text"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-12 col-md-6">
          <span className="field-label">Prediction language</span>
          <select value={state.language} onChange={(e) => setState({
            ...state,
            language: e.target.value
          })} className="form-select" aria-label="Language">
            <option value="Kannada">Kannada</option>
            <option value="English">English</option>
          </select>
        </div>
      </div>

      <div className="row mt-4">
        <p>
          <span className="note">NOTE:</span>
          <span className="note-text">
            Enter all details accurately, Kundali/Jataka will be created as per
            your information. If you are not accurate about above details
            contact personally.
          </span>
        </p>
      </div>

      <div className="d-grid gap-2 col-6 mx-auto mt-4">
        <button type="button" className="btn btn-danger btn-lg submit-btn " onClick={submit}>
          Request Prediction
        </button>
      </div>

      <Modal open={showModal} title="Pay now" onClose={() => setShowModal(false)} onButtonClick={submitAndUploadData} btnTitle="DONE">
        <div className="text-center row">
          <a href="upi://pay?pa=8277698481@ybl&pn=ramesh&mc=0000&mode=02&purpose=00" target="_blank" rel='noreferrer'>
            <img src={qrcode} alt="" />
          </a>
          <p>On mobile you can tap on the QRcode directly to open payment app without scaning.</p>
          <p>Please Pay <b>₹199/-</b> and upload a screenshot/receipt of payment.</p>
          <input type="file" accept="image/jpeg" onChange={(e) => setFile(e.target.files[0])} name="payment" id="payment" />
        </div>
      </Modal>
      <Modal open={showUploadConfirmation} title="Pay now" onClose={() => setUploadConfirmation(false)} onButtonClick={() => setUploadConfirmation(false)}>
        <div>
          <p>Your Prediction Request Placed Successfully.</p>
          <p>We will contact you shortly and we will send your predictions to your email and whatsapp.</p>
        </div>
      </Modal>
      <Progressbar open={showProgress} />
    </div>
  );
}
