// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {
  getStorage,
  ref as fileRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import {
  endBefore,
  get,
  getDatabase,
  limitToLast,
  orderByChild,
  push,
  query,
  ref,
  set,
  serverTimestamp,
} from "firebase/database";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZKQgxKoy0bO0jheaZfCtHmO2BtihMMcM",
  authDomain: "mastroclick-e49ca.firebaseapp.com",
  databaseURL:
    "https://mastroclick-e49ca-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mastroclick-e49ca",
  storageBucket: "mastroclick-e49ca.appspot.com",
  messagingSenderId: "555302329610",
  appId: "1:555302329610:web:d1f50c5d36a5f173d2eef3",
  measurementId: "G-Q52EW2DM3Z",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();

export const placeOrder = (orderData) => {
  const postListRef = ref(database, "orders");
  const newPostRef = push(postListRef);
  return set(newPostRef, { ...orderData, created_at: serverTimestamp() });
};

export const getOrders = (lastCreatedAt) => {
  const dbRef = ref(database, "orders");
  let ordersQuery = query(dbRef, orderByChild("created_at"), limitToLast(50));
  if (lastCreatedAt) {
    ordersQuery = query(ordersQuery, endBefore(lastCreatedAt));
  }
  return get(ordersQuery);
};

export const uploadScreenshot = (file, phoneNumber) => {
  const fileName = `${phoneNumber}-${new Date().getTime()}`;
  const storageRef = fileRef(storage, "/payment_screenshots/" + fileName);
  return uploadBytes(storageRef, file, { contentType: "image/jpeg" }).then(
    (uploadResult) => getDownloadURL(uploadResult.ref)
  );
};

export const setGallery = (galleryData) => {
  const postListRef = ref(database, "gallery");
  const newPostRef = push(postListRef);
  return set(newPostRef, { ...galleryData, created_at: serverTimestamp() });
};

export const getGalleryData = (lastCreatedAt) => {
  const dbRef = ref(database, "gallery");
  let galleryQuery = query(dbRef, orderByChild("created_at"), limitToLast(8));
  if (lastCreatedAt) {
    galleryQuery = query(galleryQuery, endBefore(lastCreatedAt));
  }
  return get(galleryQuery);
};

export const uploadGalleryImage = (file, title) => {
  const fileName = `${title}`;
  const storageRef = fileRef(storage, "/gallery_images/" + fileName);
  return uploadBytes(storageRef, file, { contentType: "image/jpeg" }).then(
    (uploadResult) => getDownloadURL(uploadResult.ref)
  );
};

export const isAuthenticated = () =>
  new Promise((resolve, reject) =>
    getAuth().onAuthStateChanged(resolve, reject, resolve)
  );

export const loginToGoogle = () => {
  signInWithPopup(getAuth(), provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log(token, user);
    })
    .catch((error) => {});
};

export const markOrderAsCompleted = (dbRef) => {
  set(dbRef, true);
};
