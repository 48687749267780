import Home from "../Main";
import Admin from "../Admin";
import OrderManagement from "../Admin/orders";
import ServicesManagement from "../Admin/services";
import ArticlesManagement from "../Admin/articles";
import GalleryManagement from "../Admin/gallery";

export const homePath = "/";
export const adminPath = "/admin";
export const adminOrderPath = adminPath + "/orders";
export const adminServicesPath = adminPath + "/services";
export const adminArticlesPath = adminPath + "/articles";
export const adminGalleryPath = adminPath + "/gallery";

export const routesConfig = [
  {
    path: homePath,
    component: Home,
  },
  {
    path: adminPath,
    component: Admin,
    subRoutes: [
      {
        path: adminOrderPath,
        component: OrderManagement,
        isIndex: true,
      },
      {
        path: adminServicesPath,
        component: ServicesManagement,
      },
      {
        path: adminArticlesPath,
        component: ArticlesManagement,
      },
      {
        path: adminGalleryPath,
        component: GalleryManagement,
      },
    ],
  },
];
