import React, { useState } from "react";
import "./index.css";
import logoImg from "../../assets/logo.png";

export default function Navbar(props) {
  const [avtiveIndex, setActiveIndex] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const navItems = [
    { name: "Home", id: "1", href: "#" },
    { name: "About", id: "2", href: "#about" },
    { name: "Gallery", id: "3", href: "#gallery" },
    // { name: "Services", id: "3", href: "#" },
    { name: "Contact", id: "4", href: "#contact" },
  ];

  const handleNavClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="row sticky-top bg-white">
      <nav className="navbar navbar-expand-lg">
        <a className="navbar-brand" href="/">
          <img src={logoImg} height="62" alt="" />
        </a>
        <button
          className="navbar-toggler me-5"
          type="button"
          onClick={() => setShowMenu(!showMenu)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse me-lg-5 ${
            showMenu ? "show" : ""
          }`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav m-auto mb-2 mb-lg-0">
            {navItems.map((navItem, index) => (
              <li
                className={`nav-item ${avtiveIndex === index ? "active" : ""}`}
              >
                <a
                  className="nav-link text-center"
                  aria-current="page"
                  href={navItem.href}
                  onClick={() => handleNavClick(index)}
                >
                  {navItem.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
}
