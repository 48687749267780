import React from 'react';

function Modal(props) {
    return (
        props.open && <div class="modal show d-block fade" style={{ backdropFilter: "brightness(0.4)" }}>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{props.title || ''}</h5>
                        <button type="button" class="close" aria-label="Close" onClick={props.onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {props.children}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={props.onButtonClick}>{props.btnTitle || 'Ok'}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;