import React from "react";
import PropTypes from "prop-types";
import "./index.css";
import bgImage from "../../assets/background.png";
// import playBtn from "../../assets/play.png";
GalleryCard.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  date: PropTypes.string,
  days: PropTypes.string,
  id: PropTypes.string,
  heading: PropTypes.string,
};

function GalleryCard(props) {
  return (
    <div class="card">
      <div>
        <img
          src={props.image || bgImage}
          className="card-img-top"
          alt="..."
          height={200}
        />
        {/* <img src={playBtn} className="play-btn" alt="" /> */}
      </div>
      <div className="card-body">
        <h5 className="card-title">{props.heading || "Card title"}</h5>
        <div className="row text-align-baseline">
          <div className="col p-0 text-success fw-bold fs-6">
            {props.days} day(s) ago
          </div>
        </div>
      </div>
    </div>
  );
}

export default GalleryCard;
