import React from "react";
import Navbar from "../../components/Navbar";
import About from "../About";
// import Articles from "../Articles";
import Footer from "../Footer";
import Gallery from "../Gallery";
import Home from "../Home";
// import Services from "../Services";

export default function Main() {
  return (
    <>
      <Navbar />
      <Home />
      <About />
      <Gallery />
      {/* <Services />
      <Articles />
       */}
      <Footer />
    </>
  );
}
