import React from "react";
import "./index.css";
import logoImg from "../../assets/logo.png";
import { FaShoppingBag, FaNewspaper } from "react-icons/fa";
import { MdMiscellaneousServices } from "react-icons/md";

export default function Sidebar() {
  const path = window.location.pathname.split("/").pop();
  return (
    <>
      <div className="sidebar">
        <img src={logoImg} height="125" alt="" className="logo-img" />
        <a className={path === "orders" && "active"} href="admin/orders">
          <FaShoppingBag /> Order
        </a>
        <a className={path === "services" && "active"} href="admin/services">
          <MdMiscellaneousServices /> Services
        </a>
        <a className={path === "articles" && "active"} href="admin/articles">
          <FaNewspaper /> Articles
        </a>
        <a className={path === "gallery" && "active"} href="admin/gallery">
          <FaNewspaper /> Gallery
        </a>
      </div>
    </>
  );
}
