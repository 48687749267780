const RouteComponent = (props) => {
  const getComponent = () => <props.component />;

  return props.layout ? (
    <props.layout>{getComponent()}</props.layout>
  ) : (
    getComponent()
  );
};

export default RouteComponent;
