import React, { useEffect, useState } from "react";
import "./index.css";
import medicalAstronomyRed from "../../assets/medical_astrology_red.png";
import medicalAstronomyYellow from "../../assets/medical_astronomy_yellow.png";
import GalleryCard from "../../components/GalleryCard";
import { getGalleryData } from "../../services/firebase";
import { getDays } from "../Admin/gallery";

export default function Gallery() {
  const [galleryData, setGalleryData] = useState([]);

  const fetchGalleryImages = (created_at) => {
    getGalleryData(created_at).then((db_gal_list) => {
      if (db_gal_list.hasChildren()) {
        let tempGallery = [];
        db_gal_list.forEach((db_gal) => {
          const title = db_gal.child("title").val();
          const image = db_gal.child("image").val();
          const days = getDays(db_gal.child("created_at").val());
          const createdDate = db_gal.child("created_at").val();
          tempGallery.push({
            title,
            image,
            days,
            createdDate,
          });
        });
        setGalleryData([...galleryData, ...tempGallery.reverse()]);
      }
    });
  };

  useEffect(() => {
    fetchGalleryImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="section" id="gallery">
      <div className="d-none d-md-block position-absolute animate-left">
        <img src={medicalAstronomyYellow} alt="" />
      </div>
      <div className="d-none d-md-block position-absolute right-img animate-right">
        <img src={medicalAstronomyRed} alt="" />
      </div>
      <div className="row">
        <div className="d-flex justify-content-center">
          <div className="section-heading">Gallery</div>
          <div className="section-header-border" />
        </div>
      </div>
      <div className="container mt-2">
        <div className="row">
          {galleryData.map((gallery) => {
            return (
              <div className="col-12 col-lg-3 mb-1">
                <GalleryCard
                  image={gallery.image}
                  heading={gallery.title}
                  days={gallery.days}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="row justify-content-center text-center mt-5 fw-bold">
        <div
          className="text-danger"
          onClick={() =>
            fetchGalleryImages(galleryData[galleryData.length - 1]?.createdDate)
          }
        >
          SEE MORE
        </div>
        <div className="see-more-line" />
      </div>
    </div>
  );
}
