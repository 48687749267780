import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RouteComponent from "./routeComponent";
import { routesConfig } from "./containers/Utils/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
function App() {
  const buildRoutes = (routes) => routes.map((route) => (
    <Route
      path={route.path}
      index={route.isIndex}
      element={
        <RouteComponent
          layout={route.layout}
          component={route.component}
        />
      }
    >
      {route.subRoutes && buildRoutes(route.subRoutes)}
    </Route>
  ))
  return (
    <>
      <Router>
        <Routes>
          {buildRoutes(routesConfig)}
        </Routes>
      </Router>
    </>
  );
}

export default App;
